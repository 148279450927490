@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  background: #222222;
}

.calculator-grid {
  justify-content: center;
  display: grid;
  margin-top: 2rem;
  grid-template-columns: repeat(4, 6rem);
  grid-template-rows: minmax(7rem, auto) repeat(5, 6rem);
}

.calculator-grid > button {
  cursor: pointer;
  font-size: 2rem;
  border: 1px solid white;
  outline: none;
  color: white;
  background-color: #383838;
}

.calculator-grid > button:hover,
.calculator-grid > button:focus {
  background-color: #282828;
}

.span-two {
  grid-column: span 2;
}

.output {
  grid-column: 1 / -1;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: .75rem;
  word-wrap: break-word;
  word-break: break-all;
}
.output .previous-operand {
  color: #ffffffc0;
  font-size: 1.5rem;
}
.output .current-operand {
  color: #fff;
  font-size: 2.5rem;
}